export const centeredCell = {
  textAlign: "center"
}

export function dateFromDateTime(datetime) { 
  return datetime.slice(0, 10);
}

export const api_url = process.env.REACT_APP_BACKEND_URL;

export function nameFormatter(name) {
  if (name.length <= 16) return name;
  return `${name.slice(0,16)}..`; 
}

//a paraméterként bepasszolt objektum tömböt rendezi a második paraméterben megadott obj. tulajdonság szerint, a harmadik paraméterként érkező irányban (növekvő v. csökkenő)
export function stringAlapuRendezes(objArray, property, direction) {
  objArray.sort((a, b) => {
    //ha nem szám típusú, akkor karakterlánc és akkor case in-sensitive lesz
    let fa = isNaN(a[property]) ? a[property].toLowerCase() : a[property];
    let fb = isNaN(b[property]) ? b[property].toLowerCase() : b[property];

    if (fa < fb) {
      let res = direction === "asc" ? 1 : -1;
      return res;
    }
    if (fa > fb) {
      let res = direction === "asc" ? -1 : 1;
      return res;
    }
    return 0;
  });
}

//a paraméterként bepasszolt objektum tömböt rendezi a második paraméterben megadott obj. tulajdonság szerinti csökkenő sorrendben
export function datumSzerintiRendezes(objArray, property, direction) {
  objArray.sort((a, b) => {
    let da = new Date(a[property]);
    let db = new Date(b[property]);

    if (da < db) {
        let res = direction === "asc" ? 1 : -1;
        return res;
    }
    if (da > db) {
      let res = direction === "asc" ? -1 : 1;
      return res;
    }
    return 0;
  });
}

