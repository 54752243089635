import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';

function Auth() {

    const url = process.env.REACT_APP_BACKEND_URL;

    const [authData, setAuthData] = useState({
        username: "",
        password: "",
    });
    const [isSending, setIsSending] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [cookies, setCookie] = useCookies(["accessToken"]);
    const navigate = useNavigate();

    //let accessToken = "";
    
    const login = async (authData)=> {
        try {
            let res = await axios.post(`${url}/login`, 
                { authData }, 
                { withCredentials: true }
            );
            setCookie("accessToken", res.data.accessToken);
            navigate("/");
        } catch (err) {
            setLoginError(err?.response?.data?.errormsg ?? err.message ?? "Ismeretlen hiba");
            setAuthData(prev => { 
                return {...prev, password: ""}
            });
        }
    }
      
    const fetchHitelesitessel = axios.create();

    //before send
    fetchHitelesitessel.interceptors.request.use(
        (config) => {
            if (!cookies.accessToken) {
                return config;
            }
        
            return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${cookies.accessToken}`,
            }
            };
        },
        (error) => Promise.reject(error)
    );

    //if response
    fetchHitelesitessel.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }
        
            const originalRequest = error.config;
        
            if (originalRequest.isRetry) {
                return Promise.reject(error);
            }
        
            originalRequest.isRetry = true;
            
            return axios
            .get(`${url}/get-new-access-token`, {
                withCredentials: true,
            })
            .then((res) => {
                //accessToken = res.data.accessToken;
                setCookie("accessToken", res.data.accessToken);
            })
            .then(() => fetchHitelesitessel(originalRequest));
        }
    )

    async function sendAuthData() {
        setIsSending(true);
        try {
            await login(authData);
        } catch (err) {
            console.log(err);
        } finally {
            setIsSending(false);
        }
    }
    
    const handleChange=(e)=>{
        const {name, value} = e.target;
        setAuthData(prev => {
            return {
            ...prev, [name]: value
            }
        });
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        //console.log(authData);
        sendAuthData();
    }

  return (
    <div className='auth-container'>
        <h1>Bejelentkezés</h1>
        <form onSubmit={e=>handleSubmit(e)} className='loginForm'>
           <div>
            {/* <label htmlFor="username">Felhasználónév</label> */}
                <input 
                    type="text" 
                    name="username" 
                    id="username" 
                    value={authData.username}
                    onChange={e=>handleChange(e)}
                    placeholder="Felhasználónév"
                    required
                />
           </div>
            <div>
                {/* <label htmlFor="password">Jelszó</label> */}
                <input 
                    type="password" 
                    name="password" 
                    id="password"
                    value={authData.password} 
                    onChange={e=>handleChange(e)}
                    placeholder="Jelszó"
                    required
                />
            </div>
            <div>
                <input type="submit" value="Belépés" disabled={isSending} />
            </div>
        </form>

        <div className="login-error">
            {loginError}
        </div>
      
    </div>
  )
}

export default Auth;
