import React, {useState, useEffect} from 'react';
import { api_url } from '../utils';
import axios from 'axios';
import MyModal from './MyModal';
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";

function SubsType() {

    useEffect(()=>{
        fetchSubsTypes();
    },[]);

    const defaultSubsType = {
        name: "",
        price: "",
        period: "",
        description: "",
    };
    const [subsTypes, setSubTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    //const [error, setError] = useState("");
    const [newSubsType, setNewSubsType] = useState(defaultSubsType);
    const [lastModifiedId, setLastModifiedId] = useState(-1);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [cookies] = useCookies("accessToken");

    const navigate = useNavigate();

    useEffect(()=>{
        if (!cookies.accessToken || cookies.accessToken === "")
          navigate("/login");
      }, [cookies]);

    const openDialog = () => {
        setIsOpen(true);
    }

    const closeDialog = () => {
        setIsOpen(false);
    }

    /* useEffect(()=>{
        if (subsTypes.length > 0) {
            console.log(subsTypes);
        }
    }, [subsTypes]); */

    async function fetchSubsTypes() {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`${api_url}/substypes`);
            if (Array.isArray(data) && data.length > 0)
                setSubTypes(data);
            //console.log(data);
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false);
        }
    }

    async function postSubsType() {
        setIsPosting(true);
        try {
            const {data} = await axios.post(`${api_url}/postsubstype`, {newSubsType});
            //console.log(data);
            setNewSubsType(defaultSubsType);
            fetchSubsTypes();
        } catch (err) {
            console.log(err)
        } finally {
            setIsPosting(false);
        }
    }

    function renderSubsTypesHeader() {
        const heading = <thead>
            <tr>
                <th>Megnevezés</th>
                <th>Ár</th>
                <th>Periódus</th>
                <th>Elérhető</th>
                <th>Leírás</th>
                <th>Mentés</th>
            </tr>
        </thead>;
        return heading;
    }

    function handleModify(e) {
        let {name, value} = e.target;
        console.log(`name: ${name} ,value: ${value}`);
        if (name==="price" || name==="available") {
            value = Number(value); 
        }
        const id = e.target.dataset.id;
        const modified = subsTypes.map(subt => {
            if (Number(subt.id_subscriptions_type) !== Number(id)) {
                return subt;
            }
            return {...subt, [name]: value}
        });
        setLastModifiedId(id);
        setSubTypes(modified);
    }

    function renderSubsTypes() {
        const rows = subsTypes.map((substype, ind) =>
            <tr key={ind}>
                <td>{substype.name}</td>
                <td>
                    <input 
                        type="number" 
                        name="price"
                        data-id={substype.id_subscriptions_type}
                        value={substype.price} 
                        required
                        onChange={e=>handleModify(e)}
                        className='daily-limit editable'
                    />
                </td>
                <td>{substype.period}</td>
                <td>
                    <select 
                        name="available"
                        data-id={substype.id_subscriptions_type}
                        onChange={e=>handleModify(e)}
                        value={substype.available}
                        className='editable'
                    >
                        <option value={0}>Nem</option>    
                        <option value={1}>Igen</option>    
                    </select>
                </td>
                <td>
                    <textarea 
                        name="description" 
                        cols="30" 
                        rows="3"
                        data-id={substype.id_subscriptions_type}
                        value={substype.description}
                        onChange={e=>handleModify(e)}
                    >
                    </textarea>
                </td>
                <td>
                    <button
                        onClick={e=>handleSave(e)}
                        data-id={substype.id_subscriptions_type}
                        disabled={Number(substype.id_subscriptions_type) !== Number(lastModifiedId)}
                        className='saveBtn'
                    >Mentés
                    </button>
                </td>
            </tr>
        );
        const table = <table>
            {renderSubsTypesHeader()}
            <tbody>
                {rows}
            </tbody>
        </table>;   
        return table;
    }

    async function handleSave(e) {
        //az subs tömbből kikérjük az utoljára módosított objektumot
        const modifiedObject = subsTypes.filter(substype => Number(substype.id_subscriptions_type) === Number(lastModifiedId));
                
        //console.log(modifiedObject[0]);
        if (modifiedObject[0]) {
            try {
                const {data} = await axios.patch(
                    `${api_url}/modifysubstype/${lastModifiedId}`,
                    modifiedObject[0]
                );
                //alert(data.msg);
                if (data?.msg) {
                    setDialogContent(data.msg);
                    openDialog();
                }
                setLastModifiedId(-1);
                //setDialogContent(data.msg);
                //openDialog();
            } catch(err) {
                console.log(err);
            }
        }
    }

    function handleChange(e) {
        const {name, value} = e.target;
        setNewSubsType(prev => {
            return {...prev, [name]: value}
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        postSubsType();
    }

    return (
        <>
        <h1>Előfizetés típusok kezelése</h1>
            <div className="substypes-wrapper">
                <div className="new-substype-form">
                    <h2>Új előfizetés típus megadása</h2>
                    <form onSubmit={e=>handleSubmit(e)}>
                        <input 
                            type="text" 
                            name="name" 
                            placeholder='Név'
                            value={newSubsType.name}
                            onChange={e=>handleChange(e)}
                            required
                        />
                        <input type="number" 
                            name="price" 
                            id=""
                            placeholder='Ár'
                            value={newSubsType.price} 
                            onChange={e=>handleChange(e)}
                            required
                        />
                        <input 
                            type="text" 
                            name="period" 
                            id=""
                            placeholder='Periódus'
                            value={newSubsType.period}
                            onChange={e=>handleChange(e)}
                            required
                        />
                        <textarea 
                            name="description" 
                            id="" 
                            cols="30" 
                            rows="3"
                            value={newSubsType.description}
                            onChange={e=>handleChange(e)}
                            placeholder='Leírás'
                        >
                        </textarea>

                        <button disabled={isPosting}>
                            {isPosting ? "Küldés..." : "Mentés"}
                        </button>
                    </form>
                </div>
                <div className="existing-substypes">
                    <h2>Meglévő előfizetés típusok</h2>
                    {isLoading ? 
                        "Betöltés..."
                        :
                        subsTypes.length > 0 ?
                            renderSubsTypes()
                            :
                            "Még nem hoztál létre előfizetés típust."    
                    }
                </div>
            </div>
            <MyModal 
                isOpen={isOpen}
                onClose={closeDialog}
                content={dialogContent}
            />
        </>
    )
}

export default SubsType;
