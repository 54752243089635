import Layout from './components/Layout';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Subscriptions from './components/Subscriptions';
import Auth from './components/Auth';
import { useCookies } from 'react-cookie';
import { useEffect, useState, createContext } from 'react';
import Levelkuldes from './components/Levelkuldes';
import Felhasznalok from './components/Felhasznalok';
import SubsType from './components/SubsType';
import Belepesek from './components/Belepesek';
import Letoltesek from './components/Letoltesek';

export let AuthContext = createContext();

function App() {

  const [cookies] = useCookies(["accessToken"]);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(()=>{
    if (!cookies.accessToken || cookies.accessToken === "")
      navigate("/login");
    else
      setLoggedIn(true);

  },[cookies]);

  return (
    <div className="App">
      <AuthContext.Provider value={{loggedIn, setLoggedIn}}>
        <Routes>
          <Route
            path='/'
            element={<Layout content={<Felhasznalok />}/>}
          />
          <Route
            path='/login'
            element={<Layout content={<Auth />}/>}
          />
          <Route
            path='/felhasznalok'
            element={<Layout content={<Felhasznalok />}/>}
          />
          <Route
            path='/elofizetesek'
            element={<Layout content={<Subscriptions />}/>}
          />
          <Route
            path='/elofizetes-tipusok'
            element={<Layout content={<SubsType />}/>}
          />
          <Route
            path='/letoltesek'
            element={<Layout content={<Letoltesek />}/>}
          />
          <Route
            path='/belepesek'
            element={<Layout content={<Belepesek />}/>}
          />
          <Route
            path='/levelkuldes'
            element={<Layout content={<Levelkuldes />}/>}
          />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
