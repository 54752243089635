import React, { useEffect, useRef } from 'react';

function MyModal( { isOpen, onClose, content } ) {
    const ref = useRef();

    useEffect(()=>{
        if (isOpen) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [isOpen]);

    return (
    <div>        
        {isOpen && (
            <dialog ref={ref} onCancel={onClose}>
                <p>{content}</p>
                <button onClick={onClose}>Bezárás</button>
            </dialog>)
        }
    </div>
  )
}

export default MyModal;
