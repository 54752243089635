import React, {useContext} from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../App';

function Logout() {

  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const navigate = useNavigate();

  const {setLoggedIn} = useContext(AuthContext);

  const logout = async ()=> {
    const url = process.env.REACT_APP_BACKEND_URL;
    try {
      await axios.post(`${url}/logout`, {}, { withCredentials: true });
      setCookie("accessToken", "");
      removeCookie("accessToken");
      setLoggedIn(false);
      navigate("/login");
    } catch (err) {
        alert("Sikertelen kijelentkezés!");
        console.log(err);
    }
  }

  return (
    <div className='logout'>
      {cookies.accessToken &&
        <button onClick={logout}>Kilépés</button>
      }
    </div>
  )
}

export default Logout;
