import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Layout({content}) {
  return (
    <div className='container'>
      <Header />
        <main>
            {content}
        </main>
      <Footer />
    </div>
  )
}

export default Layout;
