import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { 
  dateFromDateTime, 
  api_url, centeredCell, 
  stringAlapuRendezes,
  datumSzerintiRendezes } from '../utils';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

function Felhasznalok() {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSortAscending, setIsSortAscending] = useState(false);
    const [orderedBy, setOrderedBy] = useState({
      property: "",
      direction: "",
    });
    const [cookies] = useCookies("accessToken");

    const navigate = useNavigate();

    useEffect(()=>{
        if (!cookies.accessToken || cookies.accessToken === "")
          navigate("/login");
      }, [cookies]);
  
    useEffect(()=> {
      fetchUsers();
    }, []);

    /* useEffect(()=>{
        console.log(users);
    },[users]); */
  
    async function fetchUsers() {
      setIsLoading(true);
      try {
        const {data} = await axios.get(`${api_url}/users`);
        if (Array.isArray(data) && data.length > 0) {
          setUsers(data);
        }
        //console.log(data);
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  
    async function deleteUser(userId) {
      try {
        const {data} = await axios.delete(`${api_url}/deleteuser/${userId}`);
        fetchUsers();
        console.log(data);
      } catch (err) {
        console.log(err);
        //setError(err.message);
      }
    }
  
    function delUser(e) {
      e.preventDefault();
      let choice = window.confirm("Biztosan törölni szeretnéd?");
      if (choice) {
        //console.log(e.target.dataset.id);
        deleteUser(e.target.dataset.id);
      }
    }

    async function changeUserState(userId, active) {
      console.log(`User: ${userId}, new active value: ${active}`);
      try {
        const {data} = await axios.patch(
          `${api_url}/changeuserstate/${userId}`, {active});
        console.log(data);
      } catch (err) {
        console.log(err);
      }
    }

    function handleChange(e) {
      e.preventDefault();
      let choice = window.confirm("Biztosan módosítani szeretnéd?");
      if (choice) {
        const userId = Number(e.target.dataset.id);
        const active = Number(e.target.value);
        const modified = users.map(user => {
          if (Number(user.id_users) === userId) {
            return {...user, active}
          }
          return user;
        });
        setUsers(modified);
        changeUserState(userId, active);
      }
    }

    function sortBy(field) {
      let sortedUsers = [...users];
        //ha legutóbb növeknő sorrendbe rendeztünk, akkor most csökkenőben kell
        if (isSortAscending) {
            if (field.includes("date")) {
              datumSzerintiRendezes(sortedUsers, field, "desc");
            } else {
              stringAlapuRendezes(sortedUsers, field, "desc");
            }
        } else {
            //ha csökkenő volt legutóbb, akkor most növekvőben kell
            if (field.includes("date")) {
              datumSzerintiRendezes(sortedUsers, field, "asc");
            } else {
              stringAlapuRendezes(sortedUsers, field, "asc");
            }
        }
        setUsers(sortedUsers);
        setIsSortAscending(prev => !prev);
        setOrderedBy({
          property: field,
          direction: isSortAscending ? "asc" : "desc",
        });
    }
  
    function renderUserTableHeading() {
      const headRows = <tr>
        <th>ID</th>
        <th><span 
              className='sort' 
              onClick={()=>sortBy("email")}
            >Email
              <span data-sortby={orderedBy.property === "email" ? "true" : "false"}>
                {orderedBy.direction==="asc" ? 
                <span>&darr;</span>
                :
                <span>&uarr;</span>}
              </span>
            </span>
        </th>
        <th>
          <span 
            className='sort' 
            onClick={()=>sortBy("fullname")}
          >Teljes név
            <span data-sortby={orderedBy.property === "fullname" ? "true" : "false"}>
                {orderedBy.direction==="asc" ? 
                <span>&darr;</span>
                :
                <span>&uarr;</span>}
              </span>
          </span>
        </th>
        <th>
          <span 
            className='sort' 
            onClick={()=>sortBy("reg_date")}
          >Reg. dátuma
            <span data-sortby={orderedBy.property === "reg_date" ? "true" : "false"}>
                {orderedBy.direction==="asc" ? 
                <span>&darr;</span>
                :
                <span>&uarr;</span>}
              </span>
          </span>
        </th>
        <th><span 
              className='sort' 
              onClick={()=>sortBy("active")}
            >Aktivált
              <span data-sortby={orderedBy.property === "active" ? "true" : "false"}>
                {orderedBy.direction==="asc" ? 
                <span>&darr;</span>
                :
                <span>&uarr;</span>}
              </span>
            </span></th>
        <th><span 
              className='sort' 
              onClick={()=>sortBy("subs_end_date")}
            >Előfizetés
              <span data-sortby={orderedBy.property === "subs_end_date" ? "true" : "false"}>
                {orderedBy.direction==="asc" ? 
                <span>&darr;</span>
                :
                <span>&uarr;</span>}
              </span>
            </span>
        </th>
        <th>
          <span>
            Letiltás
          </span>
        </th>
      </tr>;
      return headRows;
    }
  
    function renderUsers() {
      let dataRows = users.map((user, ind) =>
        <tr key={ind}>
          <td>{user.id_users}</td>
          <td>{user.email}</td>
          <td>{user.fullname}</td>
          <td>{dateFromDateTime(user.reg_date)}</td>
          <td style={centeredCell}>{user.active === 1 ? 
                "Igen" 
                :
                <button 
                  onClick={e=>delUser(e)}
                  data-id={user.id_users}
                  className='deleteBtn'
                >Törlés</button>
              }
          </td>
          <td style={centeredCell}>{user.subs_end_date}</td>
          <td>
            <select 
              onChange={e=>handleChange(e)}
              value={Number(user.active)}
              data-id={user.id_users}
            >
              <option value={0}>Nem aktív</option>
              <option value={1}>Aktív</option>
            </select>
          </td>
        </tr>);
      const table = <table>
        <caption>{`${users.length} felhasználó`}</caption>
        <thead>{renderUserTableHeading()}</thead>
        <tbody>{dataRows}</tbody>
      </table>;
      return table;
    }
  
    return (
      <div>
        <h1>Felhasználók</h1>
        <div className="user-list">
          {isLoading ? 
            <div>Az adatok betöltése folyamatban...</div>
            :
            users.length > 0 ?
              renderUsers()
              :
              <p>Nincsenek felhasználók</p>
          }
        </div>
      </div>
    )
}

export default Felhasznalok;
