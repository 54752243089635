import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="created-by">
        Created by <span className='author'>Tamás Somlói</span> &copy;
      </div>
    </footer>
  )
}

export default Footer;
