import React, {useEffect, useState} from 'react';
import UserSelector from './UserSelector';
import { api_url } from '../utils';
import axios from 'axios';
import MyModal from './MyModal';
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

function Levelkuldes() {

    const defaultNewsletter = {
        subject: "",
        mailbody: "",
        addressBook: [],
    }
    const [newsletter, setNewsletter] = useState(defaultNewsletter);
    const [addresses, setAddresses] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [cookies] = useCookies("accessToken");

    const navigate = useNavigate();

    useEffect(()=>{
        if (!cookies.accessToken || cookies.accessToken === "")
          navigate("/login");
      }, [cookies]);

    const openDialog = () => {
        setIsOpen(true);
        setIsSent(true);
    }

    const closeDialog = () => {
        setIsOpen(false);
        setIsSent(false);
    }

    /* useEffect(()=>{
        console.log(newsletter);
    }, [newsletter]); */

    useEffect(()=>{
        if (addresses.length > 0) {
            setNewsletter(prev => {
                return {...prev, addressBook: addresses}
            });
        }
    }, [addresses]);

    async function postNewsletter() {
        setIsSending(true);
        try {
            const {data} = await axios.post(
                `${api_url}/newsletter`, 
                {newsletter}
            );
            if (data?.msg) {
                setDialogContent(data.msg);
                openDialog();
                setNewsletter(defaultNewsletter);
                setAddresses([]);
            } else {
                console.log(data);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsSending(false);
        }
    }

    function handleChange(e) {
        const {name, value} = e.target;
        setNewsletter(prev => {
            return {...prev, [name]: value}
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (newsletter.addressBook.length > 0) {
            postNewsletter();
            return;
        }
        alert("Hiba. Nincs kiválasztva egyetlen címzett sem!");
    }

    return (
        <>
        <h1>Email küldés</h1>
        <div className='newsletter-container'>
            <div className='newsletter-data'>
                <h2>Hírlevél írása</h2>
                <div className='addressing-display'>{addresses.length > 0 ? 
                    `Összesen ${addresses.length} címzett kijelölve` 
                    : "Nincsenek címzettek kijelölve"    
                    }
                </div>
                <form onSubmit={e=>handleSubmit(e)}>
                    <input 
                        type="text" 
                        name="subject" 
                        value={newsletter.subject}
                        onChange={e=>handleChange(e)}
                        placeholder='Levél tárgya'
                        required
                    />
                    <textarea 
                        name="mailbody" 
                        cols="50" 
                        rows="10"
                        value={newsletter.mailbody}
                        onChange={e=>handleChange(e)}
                        placeholder='Levél szövege'
                        required
                    >    
                    </textarea>
                    <input type="submit" value="Levél küldése" />
                </form>
                <div className="is-sending">
                    {isSending && "A küldés folyamatban..."}
                </div>
            </div>
            <UserSelector 
                isSent={isSent}
                setAddresses={setAddresses} 
            />
            <MyModal 
                isOpen={isOpen}
                onClose={closeDialog}
                content={dialogContent}
            />
        </div>
        </>
        
    )
}

export default Levelkuldes;
