import React, { useState, useEffect } from 'react';
import { api_url } from '../utils';
import axios from 'axios';
import MyModal from './MyModal';
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";

function Subscriptions() {

    const [subs, setSubs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastModifiedSubId, setLastModifiedSubId] = useState(-1);
    const [isOpen, setIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [cookies] = useCookies("accessToken");

    const navigate = useNavigate();

    useEffect(()=>{
        if (!cookies.accessToken || cookies.accessToken === "")
          navigate("/login");
      }, [cookies]);

    const openDialog = () => {
        setIsOpen(true);
    }

    const closeDialog = () => {
        setIsOpen(false);
    }

    useEffect(()=>{
        fetchSubs();
    }, []);

   /*  useEffect(()=>{
        console.log(lastModifiedSubId);
    }, [lastModifiedSubId]); */


    async function fetchSubs() {
        setIsLoading(true);
        const url = `${api_url}/subs`;
        try {
            const {data} = await axios.get(url);
            if (Array.isArray(data) && data.length > 0) {
                setSubs(data);
            }
            //console.log(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function deleteSub(subId) {
        try {
            const {data} = await axios.delete(`${api_url}/deletesub/${subId}`);
            fetchSubs();
            //console.log(data.msg);
          } catch (err) {
            console.log(err);
            //setError(err.message);
          }
    }

    function handleDeleteSub(e) {
        e.preventDefault();
        let choice = window.confirm("Biztosan törölni szeretnéd?");
        if (choice) {
            //console.log(e.target.dataset.id);
            deleteSub(e.target.dataset.id);
        }
    }

    function renderSubsTableHeading() {
        const headRows = <tr>
            <th>ID</th>
            <th>Típus</th>
            <th>User</th>
            <th>Kezdete</th>
            <th>Vége</th>
            <th>Napi limit</th>
            <th>Fizetve</th>
            <th>Összeg</th>
            <th>Törlés</th>
            <th>Mentés</th>
        </tr>;
        return headRows;
    }

    async function handleSave(e) {
        //alert(lastModifiedSubId);
        //az subs tömbből kikérjük az utoljára módosított objektumot
        let lastModifiedIndex = subs.findIndex(sub => 
            Number(sub.id_subscriptions) === Number(lastModifiedSubId));
        //console.log(subs[index]);
        if (-1 < lastModifiedIndex < subs.length) {
            //console.log(subs[lastModifiedIndex]);
            let toBeModifiedSub = subs[lastModifiedIndex];
            try {
                const {data} = await axios.patch(
                    `${api_url}/modifysub/${lastModifiedSubId}`,
                    toBeModifiedSub
                );
                //alert(data.msg);
                setLastModifiedSubId(-1);
                setDialogContent(data.msg);
                openDialog();
            } catch(err) {
                console.log(err);
            }
        }
    }

    function handleDLChange(e) {
        const {name, value} = e.target;
        const id = e.target.dataset.id;
        
        const modified = subs.map(sub => {
            if (Number(sub.id_subscriptions) !== Number(id)) {
                return sub;
            }
            return {...sub, [name]: Number(value)}
        });
        setLastModifiedSubId(id);
        //console.log(value);
        //console.log(modified);
        setSubs(modified);
    }

    function renderSubs() {
        let dataRows = subs.map((sub, ind) =>
            <tr key={ind}>
                <td>{sub.id_subscriptions}</td>
                <td>{sub.name}</td>
                <td>{sub.email}</td>
                <td>{sub.subs_start_date}</td>
                <td>{sub.subs_end_date}</td>
                <td>
                    <input 
                        type="number" 
                        name="daily_limit"
                        data-id={sub.id_subscriptions}
                        value={subs[ind].daily_limit}
                        onChange={e=>handleDLChange(e)}
                        className='daily-limit editable'
                    />
                </td>
                <td>
                    <select 
                        name="paid"
                        data-id={sub.id_subscriptions}
                        onChange={e=>handleDLChange(e)}
                        defaultValue={sub.paid}
                        className='editable'
                    >
                        <option value={0}>Nem</option>
                        <option value={1}>Igen</option>
                    </select>
                </td>
                <td>
                    <input 
                        type="number" 
                        name="paid_amount"
                        data-id={sub.id_subscriptions}
                        value={subs[ind].paid_amount ?? ""}
                        onChange={e=>handleDLChange(e)}
                        className='daily-limit editable'
                    />
                </td>
                <td>{sub.paid !== 1 &&
                    <button 
                        onClick={e=>handleDeleteSub(e)}
                        data-id={sub.id_subscriptions}
                        className='deleteBtn'
                    >Törlés</button>
                    }
                </td>
                <td>
                    <button 
                        onClick={e=>handleSave(e)}
                        data-id={sub.id_subscriptions}
                        disabled={Number(sub.id_subscriptions) !== Number(lastModifiedSubId)}
                        className='saveBtn'
                    >
                        Mentés
                    </button>
                </td>
            </tr>);
        const table = <table>
            <caption>{`${subs.length} előfizetés`}</caption>
            <thead>{renderSubsTableHeading()}</thead>
            <tbody>{dataRows}</tbody>
        </table>;
        return table;
    }

  return (
    <div>
      <h1>Előfizetések kezelése</h1>
      <div className="user-list">
        {isLoading ? 
          <div>Az adatok betöltése folyamatban...</div>
          :
          subs.length > 0 ?
            renderSubs()
            :
            <p>Nincsenek előfizetések</p>
        }
      </div>
      <MyModal 
        isOpen={isOpen}
        onClose={closeDialog}
        content={dialogContent}
      />
    </div>
  )
}

export default Subscriptions;
