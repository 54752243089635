import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { api_url } from '../utils';

function Letoltesek() {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState("");

    useEffect(()=>{
        fetchData();
    },[]);


    async function fetchData() {
        setIsLoading(true);
        try {
            const {data} = await axios.get(`${api_url}/downloads`);
            if (Array.isArray(data) && data.length > 0)
                setData(data);
            else
                setError("Nincs elérhető adat.");
            console.log(data);
        } catch(err) {
            setError(err?.message ?? "Egyéb hiba");
        } finally {
            setIsLoading(false);
        }
    }

    function renderTableHeading() {
        const headRows = <tr>
            <th>User</th>
            <th>User mail</th>
            <th>Dátum</th>
            <th>Tournament ID</th>
            <th>Network</th>
        </tr>;
        return headRows;
    }

    function renderData() {
        const dataRows = data.map((item, ind) => 
            <tr key={ind}>
                <td>{item.fullname}</td>
                <td>{item.email}</td>
                <td>{item.search_date}</td>
                <td>{item.tournament_id}</td>
                <td>{item.tournament_network}</td>
            </tr>
        );
        const table = <table>
            <caption>{`${data.length} letöltés`}</caption>
            <thead>{renderTableHeading()}</thead>
            <tbody>{dataRows}</tbody>
        </table>;
        return table;
    }

  return (
    <div>
      <h1>Letöltések</h1>
      <div className="downloads-container">
        {isLoading ? 
            "Betöltés..." 
            :
            data.length > 0 ?
                <div>{renderData()}</div>
                :
                <div className='error'>
                    {error}
                </div>
        }
      </div>
    </div>
  )
}

export default Letoltesek;
