import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import Logout from './Logout';
import {useCookies} from "react-cookie";
import { AuthContext } from '../App';

function Header() {

  const [cookies] = useCookies("accessToken");
  const {loggedIn} = useContext(AuthContext);

  return (
    <header>
      <Logout />
      <div className="logo">
        HRCPayouts.com - CMS
      </div>
      {loggedIn &&
        <div className="nav-cont">
          <nav>
            <ul className='main-nav'>
                <li><Link to="/felhasznalok">Felhasználók</Link></li>
                <li><Link to="/elofizetesek">Előfizetések</Link></li>
                <li><Link to="/elofizetes-tipusok">Előfizetés típusok</Link></li>
                <li><Link to="/letoltesek">Letöltések</Link></li>
                <li><Link to="/belepesek">Belépések</Link></li>
                <li><Link to="/levelkuldes">Levélküldés</Link></li>
            </ul>
          </nav>
        </div>
      }
    </header>
  )
}

export default Header;
