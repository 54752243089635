import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { api_url, nameFormatter } from '../utils';

function UserSelector( {isSent, setAddresses} ) {

    useEffect(()=>{
        if (isSent && users.length > 0) {
            let temp = users.map(user => {
                return {...user, neededToSend: false}
            });
            setUsers(temp);
        }
    },[isSent]);

    useEffect(()=> {
        fetchUsers();
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(()=>{
        //console.log(users);
        let toSend = users.filter(user => user.neededToSend).map(user => {
            return user.email
        });
        setAddresses(toSend);
    }, [users])

    async function fetchUsers() {
        setIsLoading(true);
        const url = `${api_url}/users`;
        try {
            const {data} = await axios.get(url);
            if (Array.isArray(data) && data.length > 0) {
                let extendedUsers = data.map(user => {
                    return {
                        ...user, neededToSend: false
                    }
                });
                setUsers(extendedUsers);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    function handleChange(e) {
        const userId = e.target.dataset.id;
        const neededToSend = e.target.checked;
        let updatedUsers = users.map(user => {
            if (parseInt(userId) !== parseInt(user.id_users)) {
                return user;
            }
            return {...user, neededToSend}
        });
        setUsers(updatedUsers);
    }

    function addValidSubscribers() {
        let today = new Date();
        let updatedUsers = users.map(user => {
            if (new Date(user.subs_end_date) >= today && 
                Number(user.paid) === 1) {
                    return {...user, neededToSend: true}
            }
            return {...user, neededToSend: false};
        });
        setUsers(updatedUsers);
    }

    function addOnlyNotSubscribers() {
        let today = new Date();
        let updatedUsers = users.map(user => {
            if (new Date(user.subs_end_date) >= today && 
                Number(user.paid) === 1) {
                    return {...user, neededToSend: false}
            }
            return {...user, neededToSend: true};
        });
        setUsers(updatedUsers);
    }

    function renderTableHeading() {
        const head = <thead>
            <tr>
                <th>#</th>
                <th>Név</th>
                <th>Email</th>
                <th>Aktivált fiók</th>
                <th>Előfizetés</th>
            </tr>
        </thead>;
        return head;
    }

    function renderUsers() {
        const today = new Date();
        const userRows = users.map((user, ind) => 
            <tr key={ind}>
                <td>{ind + 1}</td>
                <td>
                    <label>
                        <input 
                            type="checkbox" 
                            name="neededToSend" 
                            data-id={user.id_users}
                            checked={users[ind].neededToSend}
                            onChange={e=>handleChange(e)}
                        /> {" "} {nameFormatter(user.fullname)}
                    </label>
                </td>
                <td>{user.email}</td>
                <td className={user.active === 1 ? "green-flag" : "red-flag"}>      {user.active === 1 ? "Igen" : "Nem"}
                </td>
                <td className={new Date(user.subs_end_date) >= today 
                    && Number(user.paid) === 1 ? 
                    "green-flag" : "red-flag"}
                >
                    {new Date(user.subs_end_date) >= today 
                        && Number(user.paid) === 1 ? 
                        "Érvényes" 
                        : 
                        "nincs"}
                </td>
            </tr>
        );
        const table = <table>
            {renderTableHeading()}
            <tbody>{userRows}</tbody>
        </table>;
       return table;
    }

    function toEveryone() {
        let updatedUsers = users.map(user => {
            return {...user, neededToSend: true}
        });
        setUsers(updatedUsers);
    }

    function toNoone() {
        let updatedUsers = users.map(user => {
            return {...user, neededToSend: false}
        });
        setUsers(updatedUsers);
    }

  return (
    <div className='user-selector-container'>
        <h2>Címzettek kijelölése</h2>
        <div className='user-selector'>
        {isLoading ? 
            <div>Az adatok betöltése folyamatban...</div>
            :
            users.length > 0 ?
                (<div>
                    <div className='group-marker-buttons'>
                        <button 
                            onClick={addValidSubscribers}
                        >Előfizetők
                        </button>
                        <button 
                            onClick={addOnlyNotSubscribers}
                        >Nem előfizetők
                        </button>
                        <button 
                            onClick={toEveryone}
                        >Mindenki
                        </button>
                        <button 
                            onClick={toNoone}
                        >Senki
                        </button>
                    </div>
                    
                    {renderUsers()}
                </div>)
                :
                <p>Nincsenek felhasználók</p>
            }
        </div>
    </div>
  )
}

export default UserSelector;
